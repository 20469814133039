<template>
  <input type="button" value="Neu (Alle Standorte)" @click="newDish(false)" class="newdish newdish-all">
  <input type="button" value="Neu (Dieser Standort)" @click="newDish(true)" class="newdish newdish-one">
  <table>
    <tr>
      <th>
        Code
      </th>
      <th>
        Verfügbar
      </th>
      <th>
        Bild
      </th>
      <th>
        Name
      </th>
      <th>
        Preis
      </th>
      <th>
        Beschreibung
      </th>
      <th>
        Station 
      </th>
      <th>
        Modifiers 
      </th>
      <th>
        Brand
      </th>
      <th>
        Kategorie
      </th>
      <th>
        Allergene
      </th>
      <th>
        Zusatzstoffe
      </th>
      <th>
        Browsable 
      </th>
      <th>
       Löschen 
      </th>
    </tr>
    <tr>
      <th>
        <input id="search_code" type="text" @input="search($event.target.value)" v-model="search_code" class="dish_code" />
      </th>
      <th>
        <select id="search_available" @change="search($event.target.value)" v-model="search_available">
          <option value="both">both</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
        <br>
        <input type="button" value="&#x2611;" @click="allAvailable(dishes,true)" class="all_available">
        <input type="button" value="&#x2610;" @click="allAvailable(dishes,false)" class="all_available">
      </th>
      <th>
      </th>
      <th>
        <input id="search_name" type="text" @input="search($event.target.value)" v-model="search_name" />
      </th>
      <th>
        <input id="search_price" type="text" @input="search($event.target.value)" v-model="search_price" />
      </th>
      <th>
        <input id="search_desc" type="text" @input="search($event.target.value)" v-model="search_desc" />
      </th>
      <th>
        <input id="search_station" type="text" @input="search($event.target.value)" v-model="search_station" />
      </th>
      <th></th>
      <th>
        <input id="search_brand" type="text" @input="search($event.target.value)" v-model="search_brand" />
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th>
        <select id="search_browseable" @change.prevent.stop="search($event.target.value)" v-model="search_browseable">
          <option value="both">both</option>
          <option value="yes">yes</option>
          <option value="no">no</option>
        </select>
      </th>
      <th></th>
    </tr>

    <tr v-for="dish in dishes" :key="dish.id" tabindex="1" class="dish">
      <td>
        <div v-if="dish.has_orders">{{ dish.code }}</div>
        <input v-if="!dish.has_orders" type="text" :value="dish.code" class="dish_code" @input.prevent.stop="updateCode(dish, $event.target.value)" />
      </td>
      <td>
        <input type="checkbox" :checked="dish.available" @change.prevent.stop="toggleAvailable(dish.id)" />
      </td>
      <td>
      <input :id="dish.id + '_upload'" type="file" name="fileUpload" accept=".jpg" @change.prevent.stop="uploadImage(dish.id,dish.code,$event)">
      <label :for="dish.id + '_upload'">
       <img :src="getImage(dish)">
      </label>
      </td>
      <td>
        <input type="text" :value="dish.name" @input.prevent.stop="updateName(dish.id, $event.target.value)" class="name"/>
      </td>
      <td>
        <input type="text" :value="as_eur(dish.price)" @input.prevent.stop="updatePrice(dish.id, $event.target.value)" class="price" />
      </td>
      <td>
        <textarea :value="dish.description" @input.prevent.stop="updateDescription(dish.id, $event.target.value)" class="description"/>
      </td>
      <td align="left">
        <label v-for="station in dish.stations" :key="dish.id +'_' + station[0]">
         <input type="radio" :name="dish.id + '_station'" :value="station[0]" :checked="station[2]" @change.prevent.stop="toggleStation(dish.id,station[0],$event.target.checked)">{{ station[1] }}
        </label>
      </td>
      <td align="left">
        <label v-for="modifier in dish.modifiers" :key="dish.id +'_' + modifier[0]" class="nowrap">
        <input type="checkbox" :name="'modifier_'+modifier[0]+'_'+dish.id" :checked="modifier[2]" @change.prevent.stop="toggleModifier(dish.id,modifier[0],$event.target.checked)" />{{ modifier[1] }}
        </label>
      </td>
      <td align="left">
        <label v-for="brand in dish.brands" :key="dish.id +'_' + brand[0]">
         <input type="radio" :name="dish.id + '_brand'" :value="brand[0]" :checked="brand[2]" @change.prevent.stop="toggleBrand(dish.id,brand[0],$event.target.checked)">{{ brand[1] }}
        </label>
      </td>
      <td align="left">
        <label v-for="category in dish.categories" :key="dish.id +'_' + category[0]" class="nowrap category">
        <input type="checkbox" :name="'category_'+category[0]+'_'+dish.id" :checked="category[2]" @change.prevent.stop="toggleCategory(dish.id,category[0],$event.target.checked)" />{{ category[1] }}
        </label>
      </td>
      <td align="left">
        <label v-for="allergen in dish.allergens" :key="dish.id +'_' + allergen[0]" class="nowrap">
        <input type="checkbox" :name="'allergen_'+allergen[0]+'_'+dish.id" :checked="allergen[2]" @change.prevent.stop="toggleAllergen(dish.id,allergen[0],$event.target.checked)" />{{ allergen[1] }}
        </label>
      </td>
      <td align="left">
        <label v-for="additive in dish.additives" :key="dish.id +'_' + additive[0]" class="nowrap additive">
        <input type="checkbox" :name="'additive_'+additive[0]+'_'+dish.id" :checked="additive[2]" @change.prevent.stop="toggleAdditive(dish.id,additive[0],$event.target.checked)" />{{ additive[1] }}
        </label>
      </td>
      <td>
        <input type="checkbox" :checked="dish.browseable" @change.prevent.stop="toggleBrowseable(dish)" :disabled="checkBrowseable(dish)" />
      </td>
      <td>
        <input type="button" value="Delete" @click="deleteDish(dish)" class="deletedish">
      </td>
    </tr>
  </table>
</template>

<script>
import store from '@/store'
import debounce from "lodash.debounce"
// TODO: make helpers setup better and include this
// probably get rid of mixin
function parse_eur(eur) {
  eur = eur.replace(",", ".")
  eur = eur.replace(/\p{Z}/, "")
  eur = eur.replace("€", "")
  eur = Math.round(parseFloat(eur) * 100)
  return eur
}

const debounce_time = 1000

export default {
  name: 'Dishes',
  data() {
    return {
      refreshKey: 0,
      search_code: "",
      search_name: "",
      search_price: "",
      search_desc: "",
      search_brand: "",
      search_station: "",
      search_browseable: "both",
      search_available: "both"
    }
  },
  methods: {
  
    checkBrowseable(dish) {
      let station = false
      for(let i =0; i<dish.stations.length; i++) {
         if(dish.stations[i][2] == true) station = true
      }
      return !station;
    },
 
    toggleBrowseable(dish) {
      let station = false
      for(let i =0; i<dish.stations.length; i++) {
         if(dish.stations[i][2] == true) station = true
      }

      if(dish.browseable == false && station == false) { 
       alert('Browseable Dish must have a station!')
       return;
      }
      window.socket.send(JSON.stringify(["toggle browseable", dish.id]))
    },
    toggleAvailable(dish_id) {
      window.socket.send(JSON.stringify(["toggle available", dish_id]))
    },
    allAvailable(dishes,state) {
      let pstate = "YES"
      if(state == false) pstate = "NO"
      if(confirm("Set availability of all dishes to "+pstate+" ?") == true ) {
        for(let i=0; i<dishes.length; i++){
         let dish = dishes[i]
         console.log(dish.id, dish.available);
         if(dish.available != state) {
           console.log(dish.id,'toggle');
           window.socket.send(JSON.stringify(["toggle available", dish.id]))
          }
        }
      }
       
    },
    toggleModifier(dish_id,modifier_id,state) {
      window.socket.send(JSON.stringify(["toggle modifier", dish_id, modifier_id, state]))
    },
    toggleAllergen(dish_id,allergen_id,state) {
      window.socket.send(JSON.stringify(["toggle allergen", dish_id, allergen_id, state]))
    },
    toggleAdditive(dish_id,additive_id,state) {
      window.socket.send(JSON.stringify(["toggle additive", dish_id, additive_id, state]))
    },
    toggleCategory(dish_id,category_id,state) {
      window.socket.send(JSON.stringify(["toggle category", dish_id, category_id, state]))
    },
    deleteDish(dish) {
      if(confirm("Delete dish "+dish.code+": "+dish.name+ "?") == true )
         window.socket.send(JSON.stringify(["delete dish", dish.id]))
    },
    updatePrice: debounce( (dish_id, price) => {
      price = parse_eur(price)
      window.socket.send(JSON.stringify(["update dish", dish_id, {price: price}]))
    }, debounce_time),
    updateName: debounce( (dish_id, name) => {
      window.socket.send(JSON.stringify(["update dish", dish_id, {name: name}]))
    }, debounce_time),
    updateDescription: debounce( (dish_id, description) => {
      window.socket.send(JSON.stringify(["update dish", dish_id, {description: description}]))
    }, debounce_time),
    updateCode: debounce( (dish, code) => {
      window.socket.send(JSON.stringify(["update dish", dish.id, {code: parseInt(code)}]))
      dish.showcode = false;
    }, debounce_time),
    toggleBrand(dish_id, brand_id, state) {
      console.log('toggleBrand',dish_id,brand_id,state);
      window.socket.send(JSON.stringify(["dish toggle brand", dish_id, parseInt(brand_id), state]))
    }, 
    toggleStation(dish_id, station_id, state) {
      console.log('toggleStation',dish_id,station_id,state);
      window.socket.send(JSON.stringify(["dish toggle station", dish_id, parseInt(station_id), state]))
    }, 
    newDish(onlyThisVenue=false) {
      window.socket.send(JSON.stringify(["insert dish", onlyThisVenue]))
    }, 
    getImage(dish) {
       if(dish.image == null) dish.image = "dummy.jpg";
       return process.env.VUE_APP_API_URL + "/static/" + dish.image;
    },
    uploadImage(dish_id,dish_code,e){
      if(dish_code == 0) {
       alert("Bitte erst Code vergeben!");
       return;
      }
      var files = e.target.files || e.dataTransfer.files;
      var reader = new FileReader();
      reader.addEventListener("loadend", async function() {
           window.socket.send(JSON.stringify(["set dish image", dish_id, dish_code, this.result]))
//           console.log('read',this.result)
      })

      reader.readAsDataURL(files[0]);
    },
    search(e){
       this.refreshKey++;
       console.log(e)
    },
    inSearch(dish){
       let found = true;
       let name = document.getElementById('search_name').value
       if(name == "" || dish.name.match(name)) found = true;
       else found = false;
       return found;
    }

  },
  computed: {
    dishes() {
      this.refreshKey;
      let dishes = store.state.dishes
    
      let code = ""
      code = ""+this.search_code  
      let name = ""
      name = this.search_name  
      let price = ""
      price = (""+this.search_price).replace(",","").replace(".","") 
      let desc = ""
      desc = this.search_desc
      let sbrand = ""
      sbrand = this.search_brand
      let sstation = ""
      sstation = this.search_station 
      let browseable = "both"
      browseable = this.search_browseable
      let available = "both"
      available = this.search_available



      dishes = dishes.filter(function(dish){
           console.log(dish,name,desc)
          let show = true 

          if(show && (code == "" || (""+dish.code).toUpperCase().indexOf(code.toUpperCase()) == 0)) show = true
            else show = false

          if(show && (name == "" || dish.name.toUpperCase().match(name.toUpperCase()))) show = true
            else show = false

          if(show && (price == "" || (""+dish.price).toUpperCase().indexOf(price.toUpperCase()) == 0)) show = true
            else show = false

          if(show && (desc == "" || (dish.description && dish.description.toUpperCase().match(desc.toUpperCase())))) show = true 
            else show = false

          let brands = dish.brands
          brands = brands.filter(function(brand) { return (brand[2] == true && brand[1].toUpperCase().match(sbrand.toUpperCase())) })
          if(show && (sbrand == "" || brands.length >0)) show = true
            else show = false

          let stations = dish.stations
          stations = stations.filter(function(station) { return (station[2] == true && station[1].toUpperCase().match(sstation.toUpperCase())) })
          if(show && (sstation == "" || stations.length >0)) show = true
            else show = false

          if(show && (browseable == "both" || (browseable == "yes" && dish.browseable == true) || (browseable == "no" && dish.browseable == false) )) show = true
            else show = false

          if(show && (available == "both" || (available == "yes" && dish.available == true) || (available == "no" && dish.available == false) )) show = true
            else show = false

          return show;
        })
      return dishes
    }
  },
  mounted () {
    // ugly workaround for browser extension soft keyboards that generate no input events, and only isTrusted:false keyboard events
    if ( null == document.body.searchListener ) {
      document.body.searchListener = e => {
        console.log(e)
        if (e.target.id.startsWith('search')) {
          e.target.dispatchEvent(new Event('input'))
        }
        return true
      }
      document.body.addEventListener('keypress', document.body.searchListener)
    }
  }
}
</script>
<style>

.additive {
   overflow:hidden;
   text-overflow: ellipsis;
   width: 120px;

}

label:has(input[type="radio"]),
label:has(input[type="checkbox"]) {
  display: block;
  height: 0;
  overflow: hidden;
}

label:has(input[type="checkbox"]:checked),
label:has(input[type="radio"]:checked) {
  display: block;
  height: auto;
}

tr.dish:has(input:focus) label:has(input[type="checkbox"]), 
tr.dish:has(textarea:focus) label:has(input[type="checkbox"]),
tr.dish:focus label:has(input[type="checkbox"]),
tr.dish:has(input:focus) label:has(input[type="radio"]), 
tr.dish:has(textarea:focus) label:has(input[type="radio"]),
tr.dish:focus label:has(input[type="radio"]) {
  display: block;
  height: auto;
}



tr:has(input:focus),
tr:has(textarea:focus),
tr.dish:focus {
background-color: #9999ff;
}

input.newdish {
  margin: 0.25em 0.5em;
}

</style>

